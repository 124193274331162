import { useState, useEffect } from 'react';
import Button from '@components/ui/button';
import Image from 'next/image';
import ArrowSliderIcon from '@components/icons/arrowSlider';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';

import WakiiCaseStudies from '@img/case-study/wakii/wakii-cover.jpeg';
import ThePerfectCocktailCaseStudies from '@img/case-study/the-perfect-cocktail/tpc-cover.jpg';
import BasetalyCaseStudies from '@img/case-study/basetaly/basetaly-cover.jpg';
import GsLoftCaseStudies from '@img/case-study/gs-loft/gsloft-cover.jpg';

export default function CaseStudies() {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    breakpoints: {
      '(max-width: 640px)': {
        slides: { perView: 1.2, spacing: 20 },
      },
    },
    slides: {
      perView: 1,
      spacing: 20,
    },
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    initial: 1,
  });

  return (
    <div className="bg-black">
      <div className="relative bg-white flex justify-center items-center">
        <div className="sm:px-5 my-10 xl:my-2 ">
          {/* DIVIDERS */}
          <div className="hidden xl:block bg-black h-14 absolute top-0 left-0 w-full" />
          <div className="hidden xl:block bg-black h-14 absolute bottom-0 left-0 w-full " />
          <div className="container-blhack !px-0 w-full grid grid-cols-1 xl:grid-cols-2 justify-between items-center gap-10 py-10 md:py-0">
            {/* DESCRIPTION */}
            <div className="px-5 sm:px-0 sm:mt-10 xl:mt-0 ">
              <h2 className="title">Le ultime creazioni</h2>
              <p className="mt-4 description">
                Alcuni nostri casi di successo, spiegati nel dettaglio per farti capire la nostra
                modalità di lavoro e i KPI che prendiamo in considerazione quando ci approcciamo ad
                un nuovo progetto.
              </p>
              {/* //TODO: HIDDEN FOR NOW */}
              {/* <div className="mt-4">
                <Button text="Scoprili tutti" type="black" />
              </div> */}
            </div>
            {/* SLIDER */}
            <div className="pl-5 lg:px-5 block">
              <div className="flex items-center sm:max-w-md lg:max-w-lg mx-auto ">
                <div
                  className="hidden sm:block rotate-180 mr-4 p-2 cursor-pointer transform transition-all hover:-translate-x-2"
                  onClick={(e: any) => e.stopPropagation() || instanceRef.current?.prev()}
                >
                  <ArrowSliderIcon />
                </div>
                <div ref={sliderRef} className={`keen-slider cursor-grab`}>
                  <div key={1} className={`keen-slider__slide`}>
                    <div className="relative flex justify-center">
                      <Image
                        src={BasetalyCaseStudies}
                        alt="BasetalyCaseStudies"
                        priority={true}
                        className="rounded-xl"
                      />
                      <div className="absolute top-3/4 self-end">
                        <Button
                          text="Leggi il case study"
                          type={'white'}
                          to={`/case-study/basetaly`}
                        />
                      </div>
                    </div>
                  </div>
                  <div key={2} className={`keen-slider__slide`}>
                    <div className="relative flex justify-center">
                      <Image
                        src={ThePerfectCocktailCaseStudies}
                        alt="ThePerfectCocktailCaseStudies"
                        priority={true}
                        className="rounded-xl"
                      />
                      <div className="absolute top-3/4 self-end">
                        <Button
                          text="Leggi il case study"
                          type={'white'}
                          to={`/case-study/the-perfect-cocktail`}
                        />
                      </div>
                    </div>
                  </div>
                  <div key={3} className={`keen-slider__slide `}>
                    <div className="relative flex justify-center">
                      <Image
                        src={WakiiCaseStudies}
                        alt=" WakiiCaseStudies"
                        priority={true}
                        className="rounded-xl"
                      />
                      <div className=" absolute top-3/4 self-end">
                        <Button
                          text="Leggi il case study"
                          type={'white'}
                          to={`/case-study/wakii`}
                        />
                      </div>
                    </div>
                  </div>
                  <div key={4} className={`keen-slider__slide `}>
                    <div className="relative flex justify-center">
                      <Image
                        src={GsLoftCaseStudies}
                        alt=" GsLoftCaseStudies"
                        priority={true}
                        className="rounded-xl"
                      />
                      <div className=" absolute top-3/4 self-end">
                        <Button
                          text="Leggi il case study"
                          type={'white'}
                          to={`/case-study/gs-loft`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="ml-4 p-2 cursor-pointer hidden sm:block transform transition-all hover:translate-x-2"
                  onClick={(e: any) => e.stopPropagation() || instanceRef.current?.next()}
                >
                  <ArrowSliderIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
