import React from 'react';

export default function PrivacyIcon({ style }) {
  return (
    <div className={style}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          fill="none"
          stroke="currentColor"
        >
          <path d="M10.578,4a5,5,0,1,0,0,4H15l2,2,2-2h2l1-4Z"></path>
          <circle cx="6" cy="6" r="2" fill="currentColor" stroke="none"></circle>
          <polyline points="6 15 6 18 4.414 19.586" stroke="currentColor"></polyline>
          <polyline points="18 15 18 18 19.589 19.589" stroke="currentColor"></polyline>
          <circle cx="3" cy="21" r="2" stroke="currentColor"></circle>
          <circle cx="12" cy="21" r="2" stroke="currentColor"></circle>
          <circle cx="21" cy="21" r="2" stroke="currentColor"></circle>
          <line x1="12" y1="19" x2="12" y2="12" stroke="currentColor"></line>
        </g>
      </svg>
    </div>
  );
}
