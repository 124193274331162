import React from 'react';
import { useInView } from 'react-intersection-observer';

interface Props {
  src: string;
  poster: string;
}

export default function Video({ src, poster }: Props) {
  const [isEnded, setIsEnded] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const vidRef = React.useRef(null);
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  //PLAY/STOP IF IS IN VIEW
  React.useEffect(() => {
    if (inView && loaded) {
      vidRef.current.play();
      vidRef.current.addEventListener('timeupdate', function () {
        if (vidRef.current?.currentTime >= 2.5) {
          vidRef.current.pause();
        }
      });
      if (isEnded) {
        vidRef.current.currentTime = 0;
        setIsEnded(false);
      }
    }
    if (!inView) {
      vidRef.current.pause();
      vidRef.current.currentTime = 0;
      setIsEnded(false);
    }
  }, [inView, isEnded, loaded]);

  return (
    <div
      className={`mt-10 lg:mt-0 w-1/2 lg:w-3/4 mx-auto -z-10 shadow-2xl shadow-black ${
        inView && loaded
          ? 'scale-150 lg:scale-[2] transform transition-all duration-[2500ms] ease-in-out'
          : 'scale-100 transform transition-all duration-[2500ms] ease-in-out'
      }`}
      ref={ref}
    >
      <video
        poster={poster}
        preload="metadata"
        ref={vidRef}
        muted
        onPlay={() => setIsEnded(false)}
        onEnded={() => setIsEnded(true)}
        onCanPlayThrough={() => {
          setLoaded(true);
        }}
      >
        <source src={src} type="video/webm" />
      </video>
    </div>
  );
}
