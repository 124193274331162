import Timeline from '@components/ui/timeline';

export default function WhatWeDo() {
  return (
    <div className="container-blhack py-40 text-white">
      <div className="text-center ">
        <h2 className="big-title">
          Il design su misura <br />
          realizzato in codice
        </h2>

        <p className="mt-8 description max-w-lg px-5 sm:mx-auto">
          Come un artigiano tesse sartorialmente un abito addosso ad una persona, allo stesso modo
          noi costruiamo un design su misura sviluppato con le più recenti tecnologie. Per questo ci
          piace definirci una SOFTWARE BOUTIQUE.
        </p>
      </div>
      <div className="mt-44 isolate ">
        <Timeline data={TimelineData} />
      </div>
    </div>
  );
}

export const TimelineData = [
  {
    title: ['E-commerce'],
    descriptions: [
      'Ottieni il massimo dalla piattaforma numero uno al mondo per la gestione e-commerce.',
      'Raccontaci il tuo brand e spiegaci le tue esigenze: personalizziamo lo store con temi ottimizzati o ti forniamo consulenza su come migliorare le performance.',
    ],
    videoSrc: '/video/video-1.webm',
    poster: '/video/video-poster/video-1.webp',
    btnText: 'Scopri di piú',
    to: '/shopify-ecommerce',
  },
  {
    title: ['Applicazioni Web'],
    descriptions: [
      'Il tuo progetto merita un design ad hoc: sviluppiamo un’applicazione web dal concept iniziale al codice, realizzando un prodotto innovativo e funzionale.',
      'Che sia un’applicazione per i clienti o una soluzione enterprise, Blhack è con te.',
    ],
    videoSrc: '/video/video-2.webm',
    poster: '/video/video-poster/video-2.webp',
    btnText: 'Scopri di piú',
    to: '/web-app',
  },
  {
    title: ['Mobile App'],
    descriptions: [
      'Sviluppiamo applicazioni native per portare le tue idee sui dispositivi degli utenti finali in modo semplice e performante.',
      'Ci appoggiamo a tecnologie che riducono i costi e il time-to-market, così che tu possa raggiungere più utenti in minor tempo, senza perdita di qualità.',
    ],
    videoSrc: '/video/video-3.webm',
    poster: '/video/video-poster/video-3.webp',
    btnText: 'Scopri di piú',
    to: 'app-mobile',
  },
  //TODO: HIDDEN FOR NOW
  // {
  //   title: ['Siti Web'],
  //   descriptions: [
  //     'Avere un sito web è fondamentale, e per raggiungere gli obiettivi desiderati serve un sito con una valida progettazione ed uno sviluppo che ne garantiscano grafica piacevole, usabilità, accessibilità e contenuti creati ad hoc!',
  //     'Utilizziamo le ultime tecnologie per creare siti web che siano performanti e ottimizzati per i motori di ricerca.',
  //   ],
  //   videoSrc: '/video/video-4.webm',
  //   poster: '/video/video-poster/video-4.webp',
  //   btnText: '',
  //   to: '',
  // },
  {
    title: ['Indeciso?', 'Nessun problema!'],
    descriptions: [
      'Hai in mente un’idea ma non sai bene come metterla a terra? Esploriamola assieme! Ti aiutiamo a farla diventare realtà.',
    ],
    btnText: 'Contattaci',
    to: '#contact',
    poster: '',
  },
];
