import type { NextPage } from 'next';
import Head from 'next/head';
// Shared
import Hero from '@sections/home/Hero';
import Partners from '@sections/home/Partners';
import CaseStudies from '@components/sections/home/CaseStudies';
import WhatWeDo from '@sections/home/WhatWeDo';
import WhatWeBelive from '@sections/home/WhatWeBelive';
import Cta from '@components/shared/Cta';
import Script from 'next/script';

const Home: NextPage = () => {
  return (
    <div>
      <Head>
        <title>Blhack - Your Dreams, Our Code</title>
        <meta name="description" content="Blhack web development software house" />
      </Head>

      <main>
        <Hero />
        <Partners />
        <CaseStudies />
        <WhatWeDo />
        <WhatWeBelive />
        <Cta />
      </main>
      {/* COMPANIES-PIPEDRIVE */}
      <Script id="pipedrive" type="text/javascript">
        {` 
         (function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('lYNOR8xOBAQaWQJZ'); 
        `}
      </Script>
    </div>
  );
};

export default Home;
