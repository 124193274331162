import React from 'react';
import UsabilityIcon from '@components/icons/usability';
import PrivacyIcon from '@components/icons/privacy';
export default function WhatWeBelive() {
  return (
    <div className="clip-path-mobile lg:clip-path h-full bg-white relative">
      <div className="container-blhack py-40 lg:pb-60  ">
        <h1 className="title text-left">Il nostro credo</h1>
        {/* <p className="mt-8 description">
          I fondamenti su cui basiamo per realizzare ogni singolo progetto
        </p> */}
        <div className="flex flex-col md:flex-row md:space-x-20 mt-16">
          <div className="flex flex-col items-start w-full md:w-1/2">
            <PrivacyIcon style={'icon_what_we_belive'} />
            <h1 className="sub-title text-left mt-4">Privacy by design</h1>
            <div className="mt-8 description space-y-4">
              <p>
                Le nuove normative in tema di GDPR impongono alle aziende l’obbligo di tutelare i
                dati personali degli utenti.
              </p>
              <p>
                Tutti i nostri progetti prevedono il coinvolgimento di una figura DPO (Data
                Protection Officer), garantendoti sempre il rispetto di tutte le norme.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-start w-full md:w-1/2 mt-8 md:mt-0">
            <UsabilityIcon style={'icon_what_we_belive'} />

            <h1 className="sub-title text-left mt-4">Usabilità</h1>
            <div className="mt-8 description space-y-4">
              <p>I tuoi clienti vogliono una sola cosa: app e siti web funzionali e intuitivi.</p>
              <p>
                Per questo applichiamo le best-practice di mercato per fornire esperienze altamente
                performanti, che permettano di ottimizzare le vendite e offrire una user experience
                impeccabile.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
