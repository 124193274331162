import React from 'react';
import Image from 'next/image';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import axios from 'axios';
import Shape from '@img/shared/shape.png';
function Error({ text = 'Questo campo è obbligatorio' }) {
  return (
    <div className="flex items-center justify-start text-red-500">
      <h1 className=" mt-1 font-semibold text-sm">{text}</h1>
    </div>
  );
}
interface Form {
  fullName: string;
  email: string;
  phoneNumber: string;
}

export default function Cta() {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  let description =
    'Ti aiutiamo a trovare il potenziale nascosto delle tue idee e a trasformarlo in realtà.';
  const router = useRouter();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onError = (errors, e) => console.log(errors, e);
  const onSubmit = async (data: Form, e: any) => {
    console.log(data);
    e.preventDefault();
    setLoading(true);
    await axios
      .post('/api/create-lead', data)
      .then((res) => {
        if (res.status === 200) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 1500);
        }
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section
      id="contact"
      aria-label="Free preview"
      className="relative scroll-mt-14 bg-black sm:scroll-mt-32 bg-center bg-contain  bg-no-repeat"
    >
      <div>
        <div className="hidden lg:block absolute -top-[150px] right-[120px] z-[20]">
          <div className="relative h-full w-full ">
            <Image
              alt="background"
              src={Shape}
              width={197}
              height={248}
              objectFit="contain"
              quality={100}
            />
          </div>
        </div>
        <div className="overflow-hidden lg:relative bg-black">
          <div className="mx-auto px-4 sm:px-6 md:max-w-2xl md:px-4 lg:max-w-6xl lg:px-8 relative grid grid-cols-1 items-end gap-y-12 py-20 lg:static lg:grid-cols-2 lg:py-28 xl:py-32">
            <div>
              <h2 className="text-4xl font-extrabold tracking-tight text-white sm:w-3/4 lg:text-6xl md:w-2/3 lg:w-auto">
                HAI UN'IDEA?
              </h2>
              <h2 className="text-4xl font-extrabold tracking-tight text-white sm:w-3/4 lg:text-6xl md:w-2/3 lg:w-auto">
                SVILUPPIAMOLA!
              </h2>
              <p className="mt-4 text-lg tracking-tight text-white">{description}</p>
            </div>
            {/* Form */}
            <form className="lg:pl-16" onSubmit={handleSubmit(onSubmit, onError)}>
              {/* Name */}
              <div className="mt-4 sm:relative sm:flex sm:items-center sm:py-0.5 sm:pr-2.5">
                <div className="relative sm:static sm:flex-auto">
                  <input
                    type="text"
                    id="fullName"
                    aria-label="Full Name"
                    placeholder="Nome completo"
                    className="peer relative z-10 w-full appearance-none bg-transparent px-4 py-2 text-base text-white placeholder:text-white/70 focus:outline-none sm:py-3"
                    {...register('fullName', {
                      required: true,
                      pattern: /^[a-zA-Z ]*$/,
                      minLength: 3,
                    })}
                  />

                  <div className="absolute inset-0 rounded-md border border-white/20 peer-focus:border-black/80 peer-focus:bg-black peer-focus:ring-1 peer-focus:ring-black/80 sm:rounded-xl"></div>
                </div>
              </div>
              {errors.fullName && <Error />}
              {/* Tell */}
              <div className="mt-4 sm:relative sm:flex sm:items-center sm:py-0.5 sm:pr-2.5">
                <div className="relative sm:static sm:flex-auto">
                  <input
                    type="tel"
                    aria-label="Phone number"
                    placeholder="Numero di telefono"
                    className="peer relative z-10 w-full appearance-none bg-transparent px-4 py-2 text-base text-white placeholder:text-white/70 focus:outline-none sm:py-3"
                    {...register('phoneNumber', {
                      required: false,
                      pattern: /^[0-9]*$/,
                      minLength: 8,
                    })}
                  />
                  <div className="absolute inset-0 rounded-md border border-white/20 peer-focus:border-black/80 peer-focus:bg-black peer-focus:ring-1 peer-focus:ring-black/80 sm:rounded-xl"></div>
                </div>
              </div>
              {/* Email */}
              <div className="mt-4 sm:relative sm:flex sm:items-center sm:py-0.5 sm:pr-2.5">
                <div className="relative sm:static sm:flex-auto">
                  <input
                    type="email"
                    id="email-address"
                    aria-label="Email address"
                    placeholder="La tua email"
                    className="peer relative z-10 w-full appearance-none bg-transparent px-4 py-2 text-base text-white placeholder:text-white/70 focus:outline-none sm:py-3"
                    {...register('email', {
                      required: true,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    })}
                  />
                  <div className="absolute inset-0 rounded-md border border-white/20 peer-focus:border-black/80 peer-focus:bg-black peer-focus:ring-1 peer-focus:ring-black/80 sm:rounded-xl"></div>
                </div>
                {loading && !error && (
                  <button className="btn-form-loading">Invio in corso...</button>
                )}
                {!loading && !error && !success && (
                  <button
                    className="bnt-form"
                    type="submit"
                    onClick={() => {
                      console.log('click');
                    }}
                  >
                    Ottieni quotazione
                  </button>
                )}
                {!loading && !error && success && (
                  <button className="btn-form-success">
                    <span className="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          fill="none"
                          stroke="#fff"
                        >
                          <polyline points="6,12 10,16 18,8 "></polyline>
                        </g>
                      </svg>
                    </span>
                    Richiesta inviata!
                  </button>
                )}
              </div>
              {errors.email && <Error text="Inserire email valida" />}
              {/* ERROR AXIOS ON SUBMIT */}
              {error && !loading && <Error text="Qualcosa è andato storto, riprovare piú tardi" />}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
