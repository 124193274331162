import Button from '@components/ui/button';
import Video from '@components/shared/Video';

type Data = {
  title: string[];
  descriptions: string[];
  to: string;
  videoSrc: string;
  poster: string;
  btnText: string;
};

export default function Timeline({ data }) {
  return (
    <div>
      {data.map((el: Data, idx: number) => {
        const { title, descriptions, to, videoSrc, poster, btnText } = el;
        return (
          <div key={idx} className="flex w-full h-full flex-row ">
            <div>
              <div className={`flex flex-col h-full justify-start items-center w-[28px] `}>
                <div className="p-4 rounded-full border-[1px] border-white items-start justify-start" />
                {idx != 3 && (
                  <div
                    className={`h-full w-[1px] border-[1px] border-white ${
                      idx == 2 && 'border-dashed'
                    } `}
                  />
                )}
              </div>
            </div>

            <div
              className={`flex items-start justify-center flex-col lg:flex-row lg:space-x-20 w-full px-5  ${
                idx == 3 ? 'pb-0' : 'pb-44 lg:pb-96'
              } `}
            >
              <div className={`${idx == 3 ? 'w-full' : 'w-full lg:w-3/5'} `}>
                {title.map((el: string, idx: number) => {
                  return (
                    <div key={idx} className="title-timeline leading-10">
                      <h3>{el}</h3>
                    </div>
                  );
                })}
                <div className="mt-8 text-base sm:text-lg space-y-4">
                  {descriptions.map((desc: string, i: number) => (
                    <p key={i}>{desc}</p>
                  ))}
                  {to && (
                    <div className="pt-4">
                      <Button {...{ to }} text={btnText} type="outline-dark" />
                    </div>
                  )}
                </div>
              </div>
              {idx != 3 && (
                <div className="w-full mt-12 lg:w-2/5 flex items-start justify-start ">
                  <Video src={videoSrc} poster={poster} />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
