function TriangleSmRight() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-7" viewBox="0 0 35.627 13.562">
      <path
        id="Path_43976"
        data-name="Path 43976"
        d="M1.084,15.691h32l-11.025-11"
        transform="translate(-1.084 -3.629)"
        fill="none"
        stroke="#000"
        strokeWidth="3"
      />
    </svg>
  );
}

export default TriangleSmRight;
