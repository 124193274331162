import Image from 'next/image';

import Logo from '@img/shared/Logo.svg';

export default function Hero() {
  return (
    <div className="min-h-screen bg-black flex items-center justify-center flex-col ">
      <div className="relative w-32 md:w-48 ">
        <Image src={Logo} alt="BlhackTag" priority={true} />
      </div>
      <a href="#showMenu" className="text-center pt-20 animate-pulse cursor-pointer">
        <p className="text-white font-black text-lg">— START HERE —</p>
      </a>
    </div>
  );
}
