import React from 'react';

export default function UsabilityIcon({ style }) {
  return (
    <div className={style}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          fill="none"
          stroke="currentColor"
        >
          <rect x="2" y="19" width="20" height="4" rx="2"></rect>
          <polyline points="9 14 6 14 2.272 20"></polyline>
          <polyline points="21.728 20 18 14 15 14"></polyline>
          <line x1="12" y1="11" x2="12" y2="15" stroke="currentColor"></line>
          <polyline points="8 4 12 6 16 4" stroke="currentColor"></polyline>
          <line x1="12" y1="6" x2="12" y2="11" stroke="currentColor"></line>
          <polygon points="16 4 12 2 8 4 8 9 12 11 16 9 16 4" stroke="currentColor"></polygon>
        </g>
      </svg>
    </div>
  );
}
